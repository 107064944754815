//dev python
export const Domain = "dev-cev2pi6j.us.auth0.com";
export const clientId = "sgKVEn1s2IipWrO577kW9D0OaJWfDd6Z";
export const singup_auth =
  "https://dev-cev2pi6j.us.auth0.com/dbconnections/signup";
export const audience = "https://dev-cev2pi6j.us.auth0.com/api/v2/";
export const connection = "Aurora-Database";
export const check_evn = "dev";
export const Apis =
  "https://ykvdmxwl85.execute-api.ap-southeast-2.amazonaws.com/dev";
export const datazoo =
  "https://8dy0ifgxf4.execute-api.ap-southeast-2.amazonaws.com/dev/datazoo";
export const twilio = "https://verify1-6449-eipdfc.twil.io";

//python stag
// export const Domain = "login-aurora.invsta.io"
// export const clientId = "6TSyLPMNeSkxNmR91YNYwMTUWV2DlPyv";
// export const singup_auth ="https://dev-6lhn21ykar.au.auth0.com/dbconnections/signup";
// export const audience="https://dev-6lhn21ykar.au.auth0.com/api/v2/"
// export const connection = "Aurora-stag";
// export const check_evn="stag"
// export const Apis ="https://d20kgghfsupf2w.cloudfront.net";
// export const datazoo = "https://d1mdg5ohgq3upk.cloudfront.net/datazoo"
// export const twilio="https://verify1-6449-eipdfc.twil.io"

//test python
// export const Domain = "dev-chya15ix.au.auth0.com";
// export const clientId = "XxpTBWGFtRodkiRozTXtkMyhDJ6SmRSF";
// export const singup_auth="https://dev-chya15ix.au.auth0.com/dbconnections/signup";
// export const audience = "https://dev-chya15ix.au.auth0.com/api/v2/";
// export const connection = "Aurora-test";
// export const check_evn="test"
// export const Apis ="https://d2smajqgodfmwr.cloudfront.net";
// export const datazoo = "https://d1mdg5ohgq3upk.cloudfront.net/datazoo"
// export const twilio="https://verify-4649-zpxdez.twil.io"

//production python
//  export const Domain = "auth-aurora.invsta.io"
// export const clientId = "XrS4f0X4k4OkHbybYJcvQdsvlpsVflkl";
// export const singup_auth ="https://inv-aur-pro.au.auth0.com/dbconnections/signup";
// export const audience="https://inv-aur-pro.au.auth0.com/api/v2/"
// export const connection = "Aurora-app";
// export const check_evn="production"
// export const Apis ="https://d1r4sifx7sxy5k.cloudfront.net";
// export const datazoo = "https://d1bzh5gfwas94m.cloudfront.net/datazoo"
// export const twilio="https://verify-4649-zpxdez.twil.io"
