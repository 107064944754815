import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery";
import { Container, Row, Col } from "react-bootstrap";
import logoimage from "../images/logo.png";
import backarrow from "../images/backarrow.png";
import AxiosInstnce from "../axios";
import infobtn from "../images/infobtn.png";
import { clientId, singup_auth, connection } from "../components/Configuration";
const Seven = ({ formData, navigation }) => {
  const [agree, setAgree] = useState(false);
  const [checkedvalue, setCheckedvalue] = useState(true);

  const checkboxHandler = () => {
    setAgree(!agree);
  };
  // const btnHandler = () => {
  //   alert('The buttion is clickable!');
  // };
  const { next, go } = navigation;
  function removeError() {
    $(".error").html("");
  }
  useEffect(() => {}, [formData]);
  const previousHandler = () => {
    go("Six");
  };
  function handleClick() {
    if ($(".flirt").prop("checked") === false) {
      $(".result").html(
        "Please read and review terms and conditions and privacy policy to proceed"
      );
    } else if ($(".flirts").prop("checked") === false) {
      $(".results").html(
        "Please read and review the Product Disclosure Statement"
      );
    } else {
      onSubmit();
    }
  }
  let idCheck = sessionStorage.getItem("idCheck");
  let addressCheck = sessionStorage.getItem("addressCheck");

  let city = sessionStorage.getItem("locality");
  let postal_code = sessionStorage.getItem("postal_code");
  let country = sessionStorage.getItem("country");
  let region = sessionStorage.getItem("sublocality_level_1");
  let route = sessionStorage.getItem("route");
  let street_number = sessionStorage.getItem("street_number");
  let subpremise = sessionStorage.getItem("subpremise");
  let addressLine1 = street_number + " " + route;
  if (
    typeof formData.middleName !== "undefined" &&
    formData.middleName != null
  ) {
  } else {
    formData.middleName = " ";
  }

  let auth_singup = {
    client_id: clientId,

    email: formData.email,
    password: formData.password,
    connection: connection,

    name:
      formData.Preferred === undefined ? formData.firstName : formData.Preferred
  };
  let auth_dbdata = {
    email: formData.email,
    password: formData.password,

    name: formData.firstName
  };

  let obj = {
    idCheck: formData.idCheck === true ? true : false,
    addressCheck: formData.addressCheck === true ? true : false,
    email: formData.email,
    title: formData.title,
    gender: formData.gender,
    fullName:
      formData.firstName + " " + formData.middleName + " " + formData.lastName,
    firstName: formData.firstName,
    middleName: formData.middleName,
    lastName: formData.lastName,
    dateOfBirth: formData.dateOfBirth,
    password: formData.irdNumber + formData.firstName,
    countryCode: formData.countryCode,
    phoneNumber: formData.number,
    fullAddress: formData.fullAddress,
    addressLine1: addressLine1,
    region: region,
    city: city,
    country: country,
    postalCode: postal_code,
    subpremise: subpremise,
    identificationType: formData.identificationType,
    adviserCode: formData.adviserCode,
    identificationNumber: formData.identificationNumber,
    version: formData.version,
    expiryDate: formData.expiryDate,
    preferredName:
      formData.Preferred === undefined ||
      formData.Preferred === "" ||
      formData.Preferred === null
        ? formData.firstName
        : formData.Preferred,
    identificationIssuedBy: formData.issueBy,
    otherTypeOfId: formData.otherTypeOfId,
    description: formData.description,
    irdNumber: formData.irdNumber,
    pir: formData.pir,
    investmentFundType: formData.investmentDFundType,
    documentContent: formData.otherfile,
    carbonClick: checkedvalue
  };

  const auth_db = async () => {
    await AxiosInstnce.post("/users/auth_signup", auth_dbdata).then(res => {
      setStartDate(false);
      if (res.status === 200) {
        next();
      }
    });
  };
  // const auth_api = async () => {
  //   axios
  //     .post(singup_auth, auth_singup)
  //     .then(res => {
  //       setStartDate(false)
  //       res.status === 200 ? next() : auth_db();
  //     })
  // };

  function DeleteUnsavedImages() {}
  const [startDate, setStartDate] = useState(false);

  if (startDate === true) {
    Swal.fire({
      html: "Please wait a moment while we submit your application",
      showConfirmButton: false,
      customClass: "swalmyicon",
      didOpen: () => {
        Swal.showLoading();
      }
    });
  } else {
    if (startDate === false) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "ddddddd work has been saved",
        showConfirmButton: false,
        customClass: "swal-wide",
        willClose: DeleteUnsavedImages()
      });
    } else {
    }
  }

  const onSubmit = async () => {
    setStartDate(true);
    await AxiosInstnce.post("/users/create", obj)

      .then(res => {
        AxiosInstnce.put(`users/submit-to-mmc/${res.data.UserId}`)

          .then(res => {
            next();
            Swal.close();
          })
          .catch(error => {
            next();
            Swal.close();
          });
      })
      .catch(error => {
        Swal.fire(
          "",
          "We are unable to process your request at the moment. Please try again after some time",
          ""
        );
      });
  };

  function verifypir() {
    Swal.fire({
      html:
        "As a member of the Aurora KiwiSaver Scheme, we are thrilled to offset 100kgs of carbon on your behalf through CarbonClick, which is roughly equal to what a tree will offset by growing for 5 years. We will set you up with a CarbonClick account and credit your account with this first offset. There is no obligation for you to subscribe to any CarbonClick services, however, if you would like to opt-out, please untick the box. (In any case,we will still offset the first 100kgs even if you do opt out). If you would like to learn more about CarbonClick’s support of certified carbon off setting projects, please visit " +
        ' <a href="https://www.aurora.co.nz/climateimpact" target="_blank">www.aurora.co.nz/climateimpact</a>'
    });
  }

  return (
    <div className="form">
      <div className="headerlogo">
        <img src={logoimage} alt="" />
        <div className="headercontent">
          <h3 className="form_heading">Terms & conditions</h3>
        </div>
      </div>
      <div className="body_content margin-25">
        <div>
          <Container>
            <Row>
              <Col md={12}>
                <label>
                  By clicking Submit, you are agreeing to the below Terms and
                  Conditions, and Privacy Policy.
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="message_text bg-white mt-2" id="termss">
                  <li className="list_style">
                    <strong>
                      {" "}
                      <p className="digit">1 </p>Application of Terms and
                      Conditions
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit"> 1.1</p> If you create a User Profile
                      and choose to access services via our Platform you accept
                      and agree that you will be bound by these Terms and
                      Conditions.{" "}
                    </li>
                  </ul>
                  <li className="list_style">
                    <strong>2 Services Provided</strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">2.1 </p>Through the Platform you will
                      have access to the following services (“Services”):
                    </li>
                    <li className="list_style">
                      <p className="digit"> 2.1.1</p> General information,
                      including performance data, relating to Aurora KiwiSaver
                      Scheme and/or its range of KiwiSaver funds;
                    </li>
                    <li className="list_style">
                      <p className="digit"> 2.1.2</p> Specific information
                      relating to any investments you have made and hold through
                      Aurora;
                    </li>
                    <li className="list_style">
                      <p className="digit">2.1.3</p> Transactional information
                      and other documentation, including tax statements in
                      respect of your investment;
                    </li>
                    <li className="list_style">
                      <p className="digit"> 2.1.4</p> The ability to make, sell,
                      or switch investments.
                    </li>
                  </ul>
                  <li className="list_style">
                    <strong>
                      {" "}
                      <p className="digit">3</p> User Profile
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">3.1</p> In order to access the
                      Services via this Platform you need to create a User
                      Profile and meet the following requirements:
                    </li>
                    <li className="list_style">
                      {" "}
                      <p className="digit">3.1.1</p>
                      Be 18 years or older;
                    </li>
                    <li className="list_style">
                      <p className="digit"> 3.1.2</p> Have a New Zealand
                      domiciled bank account;
                    </li>
                    <li className="list_style">
                      <p className="digit">3.1.3 </p> Complete our verification
                      process in relation to your identity and personal
                      information to our satisfaction; and
                    </li>
                    <li className="list_style">
                      <p className="digit">3.1.4</p> Meet any other requirements
                      or provide information notified by us to you from time to
                      time.
                    </li>
                    <li className="list_style">
                      <p className="digit">3.2 </p>To create a User Profile, you
                      must submit personal information, details and copies of
                      documents via the Platform. We will rely on that
                      information in order to provide Services to you. You agree
                      that all information you submit to us is complete,
                      accurate and not misleading. If any of the information
                      provided by you changes, you must notify us immediately
                    </li>
                    <li className="list_style">
                      <p className="digit">3.3 </p>
                      You agree to take personal responsibility for any actions
                      in respect of instructions you give us through your User
                      Profile.
                    </li>
                    <li className="list_style">
                      <p className="digit">3.4 </p>
                      The User Profile may be used only for the provision of
                      Services on your behalf.
                    </li>
                    <li className="list_style">
                      <p className="digit">3.5 </p>
                      Only you may operate your User Profile. You agree to keep
                      any passwords, codes, or other security information safe
                      and secure and not to provide that information to any
                      other person. You accept that we are not responsible for
                      any loss or damage you suffer as a result of a person
                      accessing your User Profile who you have not authorised to
                      do so.
                    </li>
                    <li className="list_style">
                      <p className="digit">3.6 </p>
                      You must report any suspected loss, theft or misuse of
                      your User Profile to us immediately.
                    </li>
                    <li className="list_style">
                      <p className="digit">3.7</p>
                      You agree to give us clear, consistent and properly
                      authorised instructions via the Platform.
                    </li>
                  </ul>
                  <li className="list_style">
                    <strong>
                      {" "}
                      <p className="digit">4</p>Instructions to Execute a
                      Transaction
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">4.1</p>
                      You may make lump sum investments or regular investments
                      via the Platform.
                    </li>
                    <li className="list_style">
                      <p className="digit">4.2</p>
                      All money paid by you will be allocated to and invested in
                      accordance with your selected investment until such time
                      you instruct us to withdraw your investments.
                    </li>
                    <li className="list_style">
                      <p className="digit">4.3</p>
                      We are under no obligation to verify the authenticity of
                      any instruction or purported instruction any may act on
                      any instruction given using your User Profile without
                      further enquiry.
                    </li>
                    <li className="list_style">
                      <p className="digit">4.4 </p>
                      Any Transaction order placed by you forms a commitment,
                      which once you submit, cannot subsequently by amended or
                      revoked by you. However, our acceptance of Transactions
                      via the Platform and our execution of those Transactions
                      is at our sole discretion and subject to change at any
                      time without notice.
                    </li>
                    <li className="list_style">
                      <p className="digit">4.5</p>
                      We reserve the right to void any Transaction which we
                      consider contains any manifest error. In the absence of
                      our fraud or wilful default, we will not be liable to you
                      for any loss, cost, claim, demand, or expense following
                      any manifest error.
                    </li>
                  </ul>
                  <li className="list_style">
                    <strong>
                      {" "}
                      <p className="digit">5</p>Our Website Policy{" "}
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">5.1</p>
                      You agree to receive any and all documentations,
                      information, or other communications from Aurora
                      electronically through the Platform, by email, or
                      otherwise over the internet.
                    </li>
                    <li className="list_style">
                      <p className="digit">5.2</p>
                      notices, documents, information, amendments to the
                      agreements, or other communications transmitted to you
                      from time to time electronically.
                    </li>
                    <li className="list_style">
                      <p className="digit">5.3</p>
                      You agree that we may use the email address provided in
                      your application for a User Profile or such other email
                      address as you notify us from time to time to provide such
                      information to you. Any electronic communication will be
                      deemed to have been received by you when it is transmitted
                      by us.
                    </li>
                    <li className="list_style">
                      <p className="digit">5.4 </p>
                      Access to the Platform is at our absolute discretion. You
                      acknowledge that access to our Platform may be interrupted
                      and the Services may be unavailable in certain
                      circumstances.
                    </li>
                  </ul>
                  <li className="list_style">
                    <strong>
                      {" "}
                      <p className="digit">6</p> Risk Warnings
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">6.1</p>
                      Investment in Financial Products can present risks that
                      may impact on income and yield performance, and place your
                      savings at risk. You need to be aware of these risks that
                      may include but are not limited to market risk, company,
                      sector and country exposure risk, currency exchange risk,
                      and economic and political risk.
                    </li>
                    <li className="list_style">
                      <p className="digit">6.2</p>
                      In addition to the general risk referred to in clause 6.1,
                      you should consider the specific risks identified in the
                      relevant product disclosure statements of the Financial
                      Products you intend to invest in.
                    </li>
                  </ul>
                  <li className="list_style">
                    <strong>
                      {" "}
                      <p className="digit">7</p> Issuer and manager of the
                      Aurora KiwiSaver Scheme, and the Supervisor
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">7.1</p>
                      Implemented Investment Solutions Limited is the issuer and
                      manager of the Aurora KiwiSaver Scheme.
                    </li>
                    <li className="list_style">
                      <p className="digit">7.2</p>
                      Public Trust is the Supervisor.
                    </li>
                  </ul>
                  <li className="list_style">
                    <strong>
                      {" "}
                      <p className="digit">8</p> Dispute Resolution
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">8.1</p>
                      You will promptly inform us via the Platform and/or by
                      email of:
                    </li>
                    <li className="list_style">
                      <p className="digit">8.1.1</p>
                      Any complaints or problems with your membership, and we
                      will direct them to Implemented Investment Solutions
                      Limited (“IIS”) for resolution through its internal
                      dispute resolution process:
                    </li>

                    <li className="list_style"> </li>
                    <ul className="information">
                      <p className="text-center">
                        {" "}
                        <strong>
                          Implemented Investment Solutions Limited
                        </strong>
                      </p>
                      <p className="trust">Level 2, Woodward House</p>
                      <p className="trust">1 Woodward Street </p>
                      <p className="trust">WELLINGTON 6140 </p>
                      <p className="trust">
                        <strong>Telephone:</strong>

                        <a href="tel: (04) 499 9654 "> (04) 499 9654 </a>
                      </p>
                      <p className="trust mb-3">
                        <strong>Email:</strong>

                        <a href="contact@iisolutions.co.nz">
                          contact@iisolutions.co.nz
                        </a>
                      </p>
                    </ul>
                    <li className="list_style">
                      <p className="digit">8.1.2</p>
                      If you are not satisfied with the outcome of your
                      complaint to us, you may refer the matter to the
                      Supervisor for resolution through its internal dispute
                      resolution process:
                    </li>

                    <ul className="information">
                      <p className="text-center">
                        {" "}
                        <strong>Public Trust</strong>
                      </p>
                      <p className="trust">Corporate Trustee Services </p>
                      <p className="trust">Private Bag 5902 </p>
                      <p className="trust">WELLINGTON 6140 </p>
                      <p className="trust">
                        <strong>Telephone:</strong>

                        <a href="tel: 0800 371 471"> 0800 371 471 </a>
                      </p>
                      <p className="trust mb-3">
                        <strong>Email:</strong>

                        <a href="cts.enquiry@PublicTrust.co.nz">
                          cts.enquiry@PublicTrust.co.nz
                        </a>
                      </p>
                    </ul>
                    <li className="list_style">
                      <p className="digit">8.1.3</p>
                      If your complaint is not able to be resolved through IIS
                      internal dispute resolution process or that of the
                      Supervisor, you may refer your complaint to the dispute
                      resolution scheme operated by the Insurance and Financial
                      Services Ombudsman, an approved dispute resolution scheme
                      under the Financial Service Providers (Registration and
                      Dispute Resolution) Act 2008. IIS is a registered
                      financial service provider and member of this scheme. The
                      Insurance and Financial Services Ombudsman will not charge
                      a fee to you to investigate or resolve a complaint. The
                      contact details for the scheme are:
                    </li>

                    <ul className="information">
                      <p className="text-center">
                        {" "}
                        <strong>
                          Insurance and Financial Services Ombudsman{" "}
                        </strong>
                      </p>
                      <p className="trust">Level 8, Shamrock House</p>
                      <p className="trust">79-81 Molesworth Street </p>
                      <p className="trust">PO Box 10-845</p>
                      <p className="trust">WELLINGTON 6143 </p>
                      <p className="trust">
                        <strong>Telephone:</strong>

                        <a href="tel:  0800 888 202 "> 0800 888 202 </a>
                      </p>
                      <p className="trust mb-3">
                        <strong>Email:</strong>

                        <a href="info@ifso.nz"> info@ifso.nz</a>
                      </p>
                    </ul>
                    <li className="list_style">
                      <p className="digit">8.1.4</p>
                      The Supervisor is also a member of an approved dispute
                      resolution scheme, operated by Financial Services
                      Complaints Limited (“FSCL”). If your complaint to the
                      Supervisor has not been resolved, you can refer it to
                      FSCL. The FSCL will not charge a fee to you to investigate
                      or resolve a complaint. The contact details for the scheme
                      are:
                    </li>
                    <ul className="information">
                      <p className="text-center">
                        {" "}
                        <strong>Financial Services Complaints Limited</strong>
                      </p>

                      <p className="trust">PO Box 5967 </p>
                      <p className="trust">WELLINGTON 6145 </p>
                      <p className="trust">
                        <strong>Telephone:</strong>

                        <a href="tel: 0800 347 257 ">0800 347 257 </a>
                      </p>
                      <p className="trust mb-3">
                        <strong>Email:</strong>

                        <a href="complaints@fscl.org.nz">
                          complaints@fscl.org.nz
                        </a>
                      </p>
                    </ul>
                  </ul>

                  <li className="list_style">
                    <strong>
                      <p className="digit">9</p> Use and Disclosure of
                      Information
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">9.1</p>
                      You authorise us to:
                    </li>
                    <li className="list_style">
                      <p className="digit">9.1.1</p>
                      Collect, hold and disclose personal information about you
                      for the purpose of providing Services to you, creating
                      your User Profile and for our own Marketing purposes;
                    </li>
                    <li className="list_style">
                      <p className="digit">9.1.2</p>
                      Aggregate and anonymise your data along with that of other
                      users, and use that data ourselves or sell or supply that
                      anonymised data to other financial service providers for
                      marketing, product design, and other commercial purposes;
                    </li>
                    <li className="list_style">
                      <p className="digit">9.1.3</p>
                      Keep records of all information and instructions submitted
                      by you via the Platform or by email;
                    </li>
                    <li className="list_style">
                      <p className="digit">9.1.4</p>
                      Record and retain copies of all information and documents
                      that each Financial Adviser engaged by us is required to
                      retain for regulatory obligation purposes;
                    </li>
                    <li className="list_style">
                      <p className="digit">9.2</p>
                      You agree to give us any information we ask you for if we
                      (or any affiliated or third parties with whom you are
                      dealing with through us) believe we need it in order to
                      comply with any laws in New Zealand or overseas. You agree
                      that we can use information that we have out to:
                    </li>
                    <li className="list_style">
                      <p className="digit">9.2.1</p>
                      Assess whether we will provide you with a User Profile;
                    </li>
                    <li className="list_style">
                      <p className="digit">9.2.2</p>
                      Provide you with, or manage any of, our Services;
                    </li>
                    <li className="list_style">
                      <p className="digit">9.2.3</p>
                      Comply with any laws in New Zealand or overseas applying
                      to us or the Services we provide to you; or
                    </li>
                    <li className="list_style">
                      <p className="digit">9.2.4</p>
                      Compare with publicly available information about you or
                      information held by other reputable companies or
                      organisations we have a continuing relationship with, for
                      any of the above reasons.
                    </li>
                    <li className="list_style">
                      <p className="digit">9.3</p>
                      You agree that we can obtain information about you or give
                      your information to any of the following people or
                      organisations:
                    </li>
                    <li className="list_style">
                      <p className="digit">9.3.1</p>
                      Our agents or third parties (whether in New Zealand or
                      overseas) that provide services to, through, or via us
                      such as execution, data hosting (including cloud-based
                      storage providers) and processing, tax service, anti-money
                      laundering services or support services; or
                    </li>
                    <li className="list_style">
                      <p className="digit">9.3.2</p>A regulator or exchange for
                      the purposes of carrying out its statutory functions.
                    </li>
                    <li className="list_style">
                      <p className="digit">9.4</p>
                      You agree that where required to help us comply with laws
                      in New Zealand or overseas or if we believe giving the
                      information will help prevent fraud, money laundering or
                      other crimes, we may give information we hold about you to
                      others including:
                    </li>
                    <li className="list_style">
                      <p className="digit">9.4.1</p>
                      Police or government agencies in New Zealand and overseas;
                      or
                    </li>
                    <li className="list_style">
                      <p className="digit">9.4.2</p>
                      The issuers of Financial Products in order for them to
                      satisfy their obligations under New Zealand anti-money
                      laundering laws and regulations.{" "}
                    </li>

                    <li className="list_style">
                      <p className="digit">9.5</p>
                      We may not be allowed to tell you if we do give out
                      information about you. We are not responsible to you or
                      anyone else if we give information for the purposes above.
                      We will not disclose information about you except as
                      authorised by you or as required or authorised by law
                    </li>
                  </ul>

                  <li className="list_style">
                    <strong>
                      <p className="digit">10</p>
                      Privacy Act and your personal information{" "}
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">10.1</p>
                      We are bound by and adheres to the New Zealand Privacy Act
                      2020 for personal information to which that Act relates
                      and the Privacy Principles. These Principles govern how we
                      collect, use, hold and disclose your personal information.
                      We can change our Privacy Policy from time to time. The
                      updated policy will be made available on our website and
                      Platform.
                    </li>
                    <li className="list_style">
                      <p className="digit">10.2</p>
                      You agree to check for any changes or, otherwise, by
                      continuing to use the Platform you are considered to have
                      accepted any updated policy.
                    </li>
                    <li className="list_style">
                      <p className="digit">10.3</p>
                      You have rights of access to, and correction of, personal
                      information supplied to and held by us.
                    </li>
                  </ul>
                  <li className="list_style">
                    <strong>
                      <p className="digit">11</p>
                      Anti-Money Laundering
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">11.1</p>
                      We may need to identify you in order to comply with laws
                      in New Zealand and overseas
                    </li>
                    <li className="list_style">
                      <p className="digit">11.2</p>
                      We are required to comply with all applicable New Zealand
                      or overseas anti-money laundering laws and regulations and
                      may ask for information identifying you, and then
                      verification for such identity, including references and
                      written evidence. We may ask you for details of the source
                      or destination of your funds.
                    </li>
                    <li className="list_style">
                      <p className="digit">11.3</p>
                      You agree to complete our identification and verification
                      processes in relation to your identity and personal
                      information to our satisfaction. We reserve the right to
                      refuse to provide you Services or to cancel your User
                      Profile if this information is not provided on request.
                    </li>
                    <li className="list_style">
                      <p className="digit">11.4</p>
                      You agree that we may use personal information provided by
                      you for the purpose of electronic identity verification
                      using third party contractors and databases including the
                      Department of Internal Affairs, NZ Transport Agency,
                      Companies Office, electronic role, a credit reporting
                      agency or other entity for that purpose
                    </li>
                  </ul>

                  <li className="list_style">
                    <strong>
                      <p className="digit">12</p>
                      Limitation of Liability
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">12.1</p>
                      You agree that where our Services are acquired for
                      business purposes, or where you hold yourself out as
                      acquiring our Services for business purposes, the Consumer
                      Guarantees Act 1993 (“the CGA”) will not apply to any
                      supply of products or services by us to you. Nothing in
                      these Terms and Conditions will limit or abrogate your
                      rights and remedies under the CGA except to the extent
                      that contracting out is permitted under the CGA and all
                      provisions of these Terms and Conditions will be modified
                      to the extent necessary to give effect to that intention
                    </li>
                    <li className="list_style">
                      <p className="digit">12.2</p>
                      Subject to any terms implied by law which cannot be
                      excluded and in the absence of our fraud or wilful
                      default, we will not be liable in contract, tort
                      (including negligence), equity, or otherwise for any
                      direct, indirect, incidental, consequential, special or
                      punitive damage, or for any loss of profit, income or
                      savings, or any costs or expenses incurred or suffered by
                      you or any other person in respect of Services supplied to
                      you or in connection with your use of our Platform.
                    </li>
                    <li className="list_style">
                      <p className="digit">12.3</p>
                      You acknowledge that:
                    </li>
                    <li className="list_style">
                      <p className="digit">12.3.1</p>
                      We are entitled to rely on Information from Third Parties
                      and we are under no obligation to verify or investigate
                      that information. We will not be liable under any
                      circumstances where we rely on Information from Third
                      Parties;
                    </li>
                    <li className="list_style">
                      <p className="digit">12.3.2</p>
                      Our Services do not include tax advice. We recommend that
                      you consult your tax adviser before making a decision to
                      invest or trade in Financial Products;
                    </li>
                    <li className="list_style">
                      <p className="digit">12.4</p>
                      We will be under no liability for any loss or expense
                      which arises as a result of a delay by us in executing a
                      Transaction via the Platform (due to a network outage,
                      system failure or otherwise or for any reason whatsoever)
                    </li>
                    <li className="list_style">
                      <p className="digit">12.5</p>
                      We will not be liable for any failure to provide products
                      or services to you or to perform our obligations to you
                      under these Terms and Conditions if such failure is caused
                      by any event of force majeure beyond our reasonable
                      control, or the reasonable control of our employees,
                      agents or contractors. For the purposes of this clause, an
                      event of force majeure includes (but is not limited to) a
                      network outage, an inability to communicate with other
                      financial providers, brokers, financial intermediaries, a
                      failure of any computer dealing or settlement system, an
                      inability to obtain the necessary supplies for the proper
                      conduct of business, and the actions or failures of any
                      counterparty or any other broker or agent, or the systems
                      of that broker or agent.
                    </li>
                    <li className="list_style">
                      <p className="digit">12.6</p>
                      The provisions of this clause 11 will extend to all our
                      employees, agents and contractors, and to all corporate
                      entities in which we may have an interest and to all
                      entities which may distribute our publications.
                    </li>
                  </ul>

                  <li className="list_style">
                    <strong>
                      <p className="digit">13</p>
                      Termination of your User Profile
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">13.1</p>
                      Either you or we may cancel your User Profile at any time.
                      If we cancel your User Profile we will notify you by
                      email. If you wish to cancel your User Profile you may do
                      so using the facility available on the Platform.
                    </li>
                    <li className="list_style">
                      <p className="digit">13.2</p>
                      Examples of when we will cancel your User Profile include
                      (but are not limited to):
                    </li>
                    <li className="list_style">
                      <p className="digit">13.2.1</p>
                      In order to comply with a court order;
                    </li>
                    <li className="list_style">
                      <p className="digit">13.2.2</p>
                      If you have acted (or we have reasonable grounds to
                      suspect that you are about to act) unlawfully;
                    </li>
                    <li className="list_style">
                      <p className="digit">13.2.3</p>
                      If you have breached these Terms and Conditions;
                    </li>
                    <li className="list_style">
                      <p className="digit">13.2.4</p>
                      If you are insolvent or in liquidation or bankruptcy
                    </li>
                    <li className="list_style">
                      <p className="digit">13.3</p>
                      if either you or we terminate your User Profile you will
                      still be responsible for any Transaction made up to the
                      time of termination, and Fees for Services rendered to you
                      and our rights under these Terms and Conditions in respect
                      of those matters will continue to apply accordingly.
                    </li>
                    <li className="list_style">
                      <p className="digit">13.4</p>
                      You agree that we will not be liable for any loss you
                      suffer where we act in accordance with this clause.
                    </li>
                    <li className="list_style">
                      <p className="digit">13.5</p>
                      On termination of your User Profile, we will redeem all of
                      the investments held in your Account and transfer the
                      proceeds of sale (less any applicable Fees) to a bank
                      account nominated by you.
                    </li>
                  </ul>

                  <li className="list_style">
                    <strong>
                      <p className="digit">14</p>
                      Assignment
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">14.1</p>
                      You agree that these Terms and Conditions bind you
                      personally and you may not assign any of your rights or
                      obligations under it. Any such purported assignment will
                      be ineffective.
                    </li>
                    <li className="list_style">
                      <p className="digit">14.2</p>
                      We may assign all or any of our rights, and transfer all
                      or any of our obligations under these Terms and Conditions
                      to any person, including a purchaser of the Platform or
                      all or substantially all of our business
                    </li>
                  </ul>

                  <li className="list_style">
                    <strong>
                      <p className="digit">15</p>
                      Indemnity
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">15.1</p>
                      You must, on demand being made by us and our partners,
                      affiliated persons, officers and employees (including the
                      Platform Provider and any employees, agents and
                      contractors of the Platform Provider), indemnify those
                      persons against any and all losses, costs, claims,
                      damages, penalties, fines, expenses and liabilities:
                    </li>
                    <li className="list_style">
                      <p className="digit">15.1.1</p>
                      in the performance of their duties or exercise of their
                      authorities, except to the extent arising as a result of
                      their own negligence, fraud or wilful default; and
                    </li>
                    <li className="list_style">
                      <p className="digit">15.1.2</p>
                      which they may incur or suffer as a result of:
                    </li>
                    <li className="list_style">
                      <p className="digit">15.1.3</p>
                    </li>
                    <li className="list_style">
                      <p className="digit">15.1.3</p>
                      relying in good faith on, and implementing instructions
                      given by any person using your User Profile, unless there
                      are reasonable grounds for us to doubt the identity or
                      authority of that person; and
                    </li>
                    <li className="list_style">
                      <p className="digit">15.1.4</p>
                      relying in good faith on information you have either
                      provided to us or made available to us.
                    </li>
                    <li className="list_style">
                      <p className="digit">15.1.5</p>
                      If any person who is not you (except for the Financial
                      Markets Authority or any other regulatory authority of
                      competent jurisdiction) makes any claim, or brings any
                      proceedings in any Court, against us in connection with
                      Services we provide to you, you will indemnify us for and
                      against, and pay to us on demand, all legal costs and
                      other expenses that we incur in connection with that claim
                      or proceeding.
                    </li>
                    <li className="list_style">
                      <p className="digit">15.1.6</p>
                      You must also indemnify us and our partners, affiliated
                      persons, officers and their respective employees, agents
                      and contractors in the case of any portfolio investment
                      entity tax liability required to be deducted (at the
                      Prescribed Investor Rate nominated by you or us) from your
                      investment, even if that liability exceeds the value of
                      their investments, or any incorrect notification or
                      failure to notify or update annually your PIR or tax
                      rates.
                    </li>
                  </ul>

                  <li className="list_style">
                    <strong>
                      <p className="digit">16</p>
                      Amendments
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">16.1</p>
                      We may, at our sole discretion, amend these Terms and
                      Conditions by giving ten working days' prior notice to you
                      either by:
                    </li>
                    <li className="list_style">
                      <p className="digit">16.1.1</p>
                      Notice on our website and/or Platform; or
                    </li>
                    <li className="list_style">
                      <p className="digit">16.1.2</p>
                      Direct communication with you via email,
                      <br /> unless the change is immaterial (e.g. drafting and
                      typographical amendments) or we are required to make the
                      change sooner (e.g. for regulatory reasons), in which case
                      the changes will be made immediately.
                    </li>
                    <li className="list_style">
                      <p className="digit">16.2</p>
                      You may request a copy of our latest Terms and Conditions
                      by contacting us via the Platform or email
                    </li>
                    <li className="list_style">
                      <p className="digit">16.3</p>
                      If you access the Platform or otherwise use our Services
                      after the expiry of the notice given in accordance with
                      clause 15.1 you will be deemed to have accepted the
                      amended Terms and Conditions.
                    </li>
                  </ul>

                  <li className="list_style">
                    <strong>
                      <p className="digit">17</p>
                      Notices
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">17.1</p>
                      Any notice or other communication (“Notice”) given for the
                      purposes of these Terms and Conditions:
                    </li>
                    <li className="list_style">
                      <p className="digit">17.1.1</p>
                      Must be in writing; and
                    </li>
                    <li className="list_style">
                      <p className="digit">17.1.2</p>
                      Must be sent to the relevant party’s email address.
                    </li>
                    <li className="list_style">
                      <p className="digit">17.2</p>
                      Any notice is deemed served or received on the day it is
                      sent to the correct email address.
                    </li>
                    <li className="list_style">
                      <p className="digit">17.3</p>
                      Any notice that is served on a Saturday, Sunday or public
                      holiday is deemed to be served on the first working day
                      after that.
                    </li>
                    <li className="list_style">
                      <p className="digit">17.4</p>A notice may be given by an
                      authorised officer, employee or agent
                    </li>
                    <ul>
                      <li className="list_style">
                        <p className="digit">17.4.1</p>
                        Notice may be given personally to a director, employee
                        or agent of the party at the party’s address or to a
                        person who appears to be in charge at the time of
                        delivery or according to section 387 to section 390 of
                        the Companies Act 1993.
                      </li>
                      <li className="list_style">
                        <p className="digit">17.4.2</p>
                        If the party is a natural person, partnership or
                        association, the notice may be given to that person or
                        any partner or responsible person. If they refuse to
                        accept the notice, it may be brought to their attention
                        and left in a place accessible to them
                      </li>
                    </ul>
                  </ul>
                  <li className="list_style">
                    <strong>
                      <p className="digit">18</p>
                      Governing Law and Jurisdiction
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">18.1</p>
                      These Terms and Conditions are governed by and construed
                      according to the current laws of New Zealand. The parties
                      agree to submit to the non-exclusive jurisdiction of the
                      Courts of New Zealand
                    </li>
                    <li className="list_style">
                      <p className="digit">18.2</p>
                      If you bring any claim or proceeding against us in any
                      Court which is not a Court of New Zealand, you will
                      indemnify us for and against, and pay to us on demand, all
                      legal costs and other expenses that we incur in connection
                      with that claim or proceeding.
                    </li>
                  </ul>

                  <li className="list_style">
                    <strong>
                      <p className="digit">19</p>
                      General Interpretation
                    </strong>
                  </li>
                  <ul>
                    <li className="list_style">
                      <p className="digit">19.1</p>
                      In these Terms and Conditions:
                    </li>
                    <li className="list_style">
                      <p className="digit">19.1.1</p>
                      Unless the context otherwise requires, references to:
                    </li>
                    <li className="list_style">
                      <p className="digit">19.1.2</p>
                      “we”, “us”, “our”, “Aurora”, and “Aurora Capital” refer to
                      Aurora Capital Limited and related companies (as defined
                      in section 2(3) of the Companies Act 1993); and
                    </li>
                    <li className="list_style">
                      <p className="digit">19.1.3</p>
                      “you”, “your” and “yourself” are references to the Client
                      and where appropriate any person who you have advised us
                      are authorised to act on your behalf.
                    </li>
                    <li className="list_style">
                      <p className="digit">19.1.4</p>A reference to these Terms
                      and Conditions (including these Terms and Conditions)
                      includes a reference to that agreement as novated, altered
                      or replaced from time to time;
                    </li>
                    <li className="list_style">
                      <p className="digit">19.1.5</p>A reference to a party
                      includes the party’s administrators, successors and
                      permitted assigns;
                    </li>
                    <li className="list_style">
                      <p className="digit">19.1.6</p>
                      Words in the plural include the singular and vice versa;
                    </li>
                    <li className="list_style">
                      <p className="digit">19.1.7</p>
                      Headings are inserted for convenience only and will be
                      ignored in construing these Terms and Conditions;
                    </li>
                    <li className="list_style">
                      <p className="digit">19.1.8</p>
                      References to any legislation includes statutory
                      regulations, rules, orders or instruments made pursuant to
                      that legislation and any amendments, reenactments, or
                      replacements; and
                    </li>
                    <li className="list_style">
                      <p className="digit">19.1.9</p>
                      Expressions referring to writing will be construed as
                      including references to words printed, typewritten, or by
                      email or otherwise traced, copied or reproduced
                    </li>
                    <li className="list_style">
                      <p className="digit">19.2</p>
                      These Terms and Conditions are intended to benefit and be
                      enforceable by Aurora and its related companies (as
                      defined in section 2(3) of the Companies Act 1993) in
                      accordance with the Contracts (Privity) Act 1982.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="checkbox-my mmmmm left mt-5">
                  <label className="containers mmmmm">
                    Untick this box to opt out of CarbonClick
                    <input
                      type="checkbox"
                      id="payments"
                      // checked={checkedvalue}
                      onChange={() => setCheckedvalue(!checkedvalue)}
                      // value="term"
                      name="terms"
                      value={formData.termss ? formData.termss : ""}
                      defaultChecked
                    />
                    <input
                      type="hidden"
                      value="false"
                      id="term_conditionTest1"
                    />
                    <input
                      type="hidden"
                      value="false"
                      id="term_conditionTest2"
                    />
                    <span className="checkmark Box" />
                  </label>

                  <img
                    src={infobtn}
                    className="triger_image"
                    onClick={verifypir}
                    alt="info"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="checkbox-my left ">
                  <label className="containers">
                    I agree to the Terms and Conditions and Privacy Policy
                    <input
                      type="checkbox"
                      id="payments"
                      onClick={removeError}
                      className="flirt"
                      // value="term"
                      name="terms"
                      value={formData.terms ? formData.terms : ""}
                    />
                    <input
                      type="hidden"
                      value="false"
                      id="term_conditionTest1"
                    />
                    <input
                      type="hidden"
                      value="false"
                      id="term_conditionTest2"
                    />
                    <span className="checkmark Box" />
                  </label>
                  <div className="error result pl-34" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="checkbox-my left ">
                  <label className="containers">
                    I confirm I have downloaded and read the Product Disclosure
                    Statement
                    <br />
                    <a
                      href="https://aurora.co.nz/fund-documents/Aurora_KiwiSaver_PDS.pdf"
                      onClick={checkboxHandler}
                      target="_blank">
                      Click Here to Download
                    </a>
                    <input
                      type="checkbox"
                      // id="hover"
                      // id="paymentss"
                      // onClick={btnHandler}
                      disabled={!agree}
                      onClick={removeError}
                      className="flirts"
                      // value="terms"
                      name="termss"
                      value={formData.termss ? formData.termss : ""}
                    />
                    {/* <p id="stuff" >Please Download first</p> */}
                    <input
                      type="hidden"
                      value="false"
                      id="term_conditionTest1"
                    />
                    <input
                      type="hidden"
                      value="false"
                      id="term_conditionTest2"
                    />
                    <span className="checkmark Box" />
                  </label>
                  <div className="error results pl-34" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="footer">
                  <button
                    type="button"
                    className="preview trans_btn"
                    onClick={previousHandler}>
                    <img src={backarrow} className="arrow_size" alt="arrow" />{" "}
                    BACK
                  </button>
                  <button
                    type="button"
                    className="next grad_btn"
                    onClick={handleClick}>
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Seven;
