import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import logoimage from "../images/logo.png";
import nextarrow from "../images/nextarrow.png";
import backarrow from "../images/backarrow.png";
import $ from "jquery";
const Six = ({
  formData,
  navigation,
}) => {
  const { previous, next } = navigation;
  var setRadioValue = e => {
    formData.investmentDFundType = e.target.value;
  };

  const handleNext = () => {
    if (
      typeof formData.investmentDFundType == "undefined" ||
      formData.investmentDFundType === "" ||
      formData.investmentDFundType === null
    ) {
      $(".optionerror").html("Please select an investment fund");
    } else {
      next();
    }
  };
  useEffect(() => {

    $(".fund").on("click", function () {
      $(".fund").removeClass("current");
      $(this).addClass("current");
    });
  });

  console.log("sssssssssssssssssssssss",  formData.idCheck === true ? true : false)
  console.log("ssssssssssssssssssssssfffs",  formData.idCheck == true ? true : false)
  return (
    <div className="form">
      <div className="headerlogo side">
        <img src={logoimage} alt="" />
        <div className="headercontent">
          <h3 className="form_heading">
            Confirm your selected KiwiSaver investment strategy
          </h3>
        </div>
      </div>
      <div className="body_content margin-24">
        <div>
          <Container>
            <Row>

              <div className="d-flex align-items-center mb-4 mt-2 row">
                <Col md={4}>
                  <label className="radio mb-3 pr">
                    <input
                      type="radio"
                      className="one"
                      name="investmentDFundType"
                      value="Conservative Fund"
                      onClick={e => setRadioValue(e)}
                    />
                    <div className="flex-th cons">
                      <div
                        className={
                          formData.investmentDFundType ===
                            "Conservative Fund"
                            ? "current fund"
                            : "fund"
                        }>
                        <span />
                        <p>Conservative Fund</p>
                      </div>
                    </div>

                  </label>
                </Col>
                <Col md={4}>
                  <label className="radio mb-3 pr">

                    <input
                      type="radio"
                      className="one"
                      name="investmentDFundType"
                      value="First Home Buyers Strategy"
                      onClick={e => setRadioValue(e)}
                    />
                    <div className="flex-th First">
                      <div
                        className={
                          formData.investmentDFundType ===
                            "First Home Buyers Strategy"
                            ? "current fund"
                            : "fund"
                        }>
                        <span />
                        <p>First Home Buyers Strategy</p>
                      </div>
                    </div>

                  </label>
                </Col>

                <Col md={4}>
                  <label className="radio mb-3 pr">

                    <input
                      type="radio"
                      className="one"
                      name="investmentDFundType"
                      value="Balanced Strategy"
                      onClick={e => setRadioValue(e)}
                    />
                    <div className="flex-th Balance ">
                      <div
                        className={
                          formData.investmentDFundType === "Balanced Strategy"
                            ? "current fund"
                            : "fund"
                        }>
                        <span />
                        <p>Balanced Strategy</p>
                      </div>
                    </div>

                  </label>
                </Col>
              </div>

              <div className="d-flex align-items-center mb-4 mt-2 row">
                <Col md={4}>
                  <label className="radio mb-3 pr">

                    <input
                      type="radio"
                      className="one"
                      name="investmentDFundType"
                      value="Growth Fund"
                      onClick={e => setRadioValue(e)}
                    />
                    <div className="flex-th Growth">
                      <div
                        className={
                          formData.investmentDFundType === "Growth Fund"
                            ? "current fund"
                            : "fund"
                        }>
                        <span />
                        <p>Growth Fund</p>
                      </div>
                    </div>
                  </label>
                </Col>

                <Col md={4}>
                  <label className="radio mb-3 pr">

                    <input
                      type="radio"
                      className="one"
                      name="investmentDFundType"
                      value="Climate Growth Strategy"
                      onClick={e => setRadioValue(e)}
                    />
                    <div className="flex-th ClimateGrowth">
                      <div
                        className={
                          formData.investmentDFundType ===
                            "Climate Growth Strategy"
                            ? "current fund"
                            : "fund"
                        }>
                        <span />
                        <p>Climate Growth Strategy</p>
                      </div>
                    </div>
                  </label>
                </Col>
                <Col md={4}>
                  <label className="radio mb-3">

                    <input
                      type="radio"
                      className="one"
                      name="investmentDFundType"
                      value="RetirementPlus Strategy"
                      onClick={e => setRadioValue(e)}
                    />
                    <div className="flex-th LegacyPath ">
                      <div
                        className={
                          formData.investmentDFundType ===
                            "RetirementPlus Strategy"
                            ? "current fund"
                            : "fund"
                        }>
                        <span />
                        <p>RetirementPlus Strategy</p>
                      </div>
                    </div>
                  </label>
                </Col>
              </div>

              <span className="error optionerror b-0" />
            </Row>
            <Row>
              <Col md={12}>
                <div className="footer">
                  <button
                    type="button"
                    className="preview trans_btn"
                    onClick={previous}>
                    <img src={backarrow} className="arrow_size" alt="arrow" />{" "}
                    BACK
                  </button>
                  <button
                    type="button"
                    className="next grad_btn"
                    onClick={handleNext}>
                    NEXT
                    <img
                      src={nextarrow}
                      className="arrow_size"
                      alt="arrow"
                    />{" "}
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Six;
