import React, { useEffect , useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import logoimage from "../images/logo.png";
import nextarrow from "../images/nextarrow.png";
import backarrow from "../images/backarrow.png";
import infobtn from "../images/infobtn.png";
import $ from "jquery";
import Swal from "sweetalert2";
import InputMask from "react-input-mask";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
// import { validateIrd } from "./IRDValidatore";
import { datazoo } from "../components/Configuration";

import axios from "axios";
const Five = ({ setForm, formData, navigation }) => {
  // const { irdNumber } = formData;
  const { previous, next } = navigation;
  useEffect(() => {
    $(document).ready(function () {
      $("#hide").click(function () {
        $("#showmy").show();
        $("#hidemy").hide();
      });

      $("#show").click(function () {
        $("#hidemy").show();
        $("#showmy").hide();
      });
      $(".flagsave").click(function () {
        let country = $(
          ".counrtyselext #select_flag_button > span > span"
        ).text();
        localStorage.setItem("countryOfBirth", country);
      });
      $(document).ready(function () {
        let benIsNZCitizen = formData.benIsNZCitizen
          ? formData.benIsNZCitizen
          : "true";
        document.getElementsByClassName("toogles").value = benIsNZCitizen;
        $(".yesno").click(function () {
          benIsNZCitizen = $(this).val();
          formData.benIsNZCitizen = benIsNZCitizen;
        });
      });
      $(".flagsave").click(function () {
        let countryOfResidence = $(
          ".counrtyselext2 #select_flag_button > span > span"
        ).text();
        localStorage.setItem("countryOfResidence", countryOfResidence);
      });
    });
    $(".toogles").click(function () {
      $(".toogles").removeClass("actives");
      $(this).addClass("actives");
    });
  });

  useEffect(() => {
    amlcheck();
    amlcheckli();
   
  }, []);
  
   if(!formData.pir ) {(formData.pir = "28%")}  ;
  
  function verifypir() {
    Swal.fire({
      html:
        "If you are unsure of your PIR rate, please visit this " +
        '<a href="https://www.ird.govt.nz/roles/portfolio-investment-entities/find-my-prescribed-investor-rate" target="_blank">IRD website</a>' +
        "  to determine your correct PIR"
    });
  }
  function handleClick() {
    var btnValss = $(".postalAddressButton").hasClass("actives");
    if (
      !btnValss &&
      (typeof formData.irdNumber == "undefined" ||
        formData.irdNumber === "" ||
        formData.irdNumber == null)
    ) {
      $(".irdNumbers").html("Please enter your IRD number");
    } else if (formData.irdNumber.length < 9) {
      $(".irdNumbers")
        .html(
          "Your IRD number must be 9 digits (add 0 at the start if necessary)"
        )
        .css("color", "red");
    } else if (!(formData.irdNumber)) {
      // else if (!validateIrd(formData.irdNumber)) {
      $(".irdNumbers")
        .html("Please enter a valid IRD number")
        .css("color", "red");
    }
    
    else {
      next();
    }
  }

  function setPass(e) {
    if (e.length !== 9) {
      $(".eerror")
        .html(
          "Your IRD number must be in the format 123-456-789. If you IRD number only has 2 digits at the start, please enter a 0 before the first digit. "
        )
        .css("color", "gray");
    }

  }
  let city = sessionStorage.getItem("locality");
  let postal_code = sessionStorage.getItem("postal_code");
  let region = sessionStorage.getItem("sublocality_level_1");
  let route = sessionStorage.getItem("route");
  let street_number = sessionStorage.getItem("street_number");
  let subpremise = sessionStorage.getItem("subpremise");
  let addressLine1 = street_number + " " + route;

  let obj = {
    firstname: formData.firstName,
    middlename: formData.middleName,
    lastname: formData.lastName,
    addressLine1: addressLine1,
    region: region,
    subpremise: subpremise,
    city: city,
    idtype: "LINZ",
    postalCode: postal_code,

  }
  var a=formData.dateOfBirth.split('/');
  var b=formData.expiryDate.split('/');

  let obli;
  if (formData.identificationType === "NZ Driver Licence") {
    obli = {
      firstname: formData.firstName,
      middlename: formData.middleName,
      lastname: formData.lastName,
      idexpiry: (b[2]+ '-' +b[1]+ '-' +b[0]),
      idnumber: formData.identificationNumber,
      idversion: formData.version,
      dateofbirth: (a[2]+ '-' +a[1]+ '-' +a[0]),
      gender: formData.gender,
      idtype: "DL",
    }
  } else if (formData.identificationType === "NZ Passport") {
    obli = {
      firstname: formData.firstName,
      middlename: formData.middleName,
      lastname: formData.lastName,

      idexpiry: (b[2]+ '-' +b[1]+ '-' +b[0]),

      dateofbirth: (a[2]+ '-' +a[1]+ '-' +a[0]),
      // idversion: formData.version,
      idnumber: formData.identificationNumber,

      gender: formData.gender,
      idtype: "PP",

    }
  }
  const [value, setValue] = useState();
  const [value1, setValue1] = useState();

  const amlcheck = async () => {
    await axios.post(datazoo, obj)
      .then(res => {
        formData.addressCheck = res.data.verified

        // sessionStorage.setItem("addressCheck", res.data.verified);

        setValue1(res.data.verified);

      })
      .catch(error => {
        setValue1(false);
      });



  }


  const amlcheckli = async () => {

    await axios.post(datazoo, obli)
      .then(res => {
        formData.idCheck = res.data.verified
        // sessionStorage.setItem("idCheck", res.data.verified);

        setValue(res.data.verified);
      }).catch(error => {
        setValue(false);

      });


  }
  
  useEffect(() => {
  }, [formData.idCheck, formData.addressCheck  ]);
  
  return (
    <div className="form">
      <div className="headerlogo">
        <img src={logoimage} alt="" />
        <div className="headercontent">
          <h3 className="form_heading">Provide your tax details</h3>
        </div>
      </div>
      <div className="body_content margin-25">
        <div >
          <Container>
            <Row>
              <Col md={6}>
                <label>IRD number</label>
                <InputMask autoComplete="off"
                  mask="999-999-999"
                  maskChar={null}
                  className="full"
                  type="text"
                  // value={irdNumber}
                  name="irdNumber"
                  // maskPlaceholder={null}
                  placeholder="xxx-xxx-xxx"
                  value={formData.irdNumber ? formData.irdNumber : ""}
                  onChange={e => {
                    setForm(e);
                    setPass(e);
                  }}
                  onClick={e => {
                    setPass(e);
                  }}
                />

                <span className="error irdNumbers eerror" />
                <span className="msg-show" />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <label>
                  Select your Prescribed Investor Rate (PIR)
                  <img
                    src={infobtn}
                    className="btn_icon"
                    alt="info"
                    onClick={verifypir}
                  />
                </label>
              </Col>
              <Col md={6}>
                <select
                  id="select_pir"
                  name="pir"
                  onChange={e => {
                    setForm(e);
                  }}
                  value={formData.pir ? formData.pir : ""}>
                  <option select="true">28%</option>
                  <option>17.5%</option>
                  <option>10.5%</option>
                </select>
                {value}
                <span className="error pirerror" />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="footer">
                  <button
                    type="button"
                    className="preview trans_btn"
                    onClick={previous}>
                    <img src={backarrow} className="arrow_size" alt="arrow" />{" "}
                    BACK
                  </button>
                  {(value === true || value === false)&& (value1 === true || value1 === false)
                  ?
                    <button
                    type="button"
                    className="next grad_btn"
                    onClick={handleClick}>
                    NEXT
                    <img
                      src={nextarrow}
                      className="arrow_size"
                      alt="arrow"
                    />{" "}
                  </button>
                  :
                  <button
                  type="button"
                  className="next grad_btn"
                  >
                    {value}
                  Please Wait...
                  
                </button>

                  }
                
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Five;
