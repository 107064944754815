import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import logoimage from "../images/logo.png";
import nextarrow from "../images/nextarrow.png";
import backarrow from "../images/backarrow.png";
import $, { now } from "jquery";
import InputMask from "react-input-mask";
import ReactFlagsSelect from "react-flags-select";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import "antd/dist/antd.css";
import ItemForm from "./ItemForm";
import attach from "../attch.jpg";
const Four = ({ setForm, formData, navigation }) => {
  const { next } = navigation;
  const handleChange = e => {
    e.preventDefault();
  };
  useEffect(() => {
    $(document).ready(function() {
      $(".flagsave").click(function() {
        let country = $(
          ".counrtyselext #select_flag_button > span > span"
        ).text();
        localStorage.setItem("countryOfBirth", country);
      });
      $(document).ready(function() {
        let benIsNZCitizen = formData.benIsNZCitizen
          ? formData.benIsNZCitizen
          : "true";
        document.getElementsByClassName("toogles").value = benIsNZCitizen;
        $(".yesno").click(function() {
          benIsNZCitizen = $(this).val();
          formData.benIsNZCitizen = benIsNZCitizen;
        });
      });
      $(".flagsave").click(function() {
        let countryOfResidence = $(
          ".counrtyselext2 #select_flag_button > span > span"
        ).text();
        localStorage.setItem("countryOfResidence", countryOfResidence);
      });
    });
    $(".toogles").click(function() {
      $(".toogles").removeClass("actives");
      $(this).addClass("actives");
    });

    $(".toogles").click(function() {
      $(".toogles").removeClass("actives");
      $(this).addClass("actives");
    });
    function readURL(input, imgControlName) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
          $(imgControlName).attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
        $("#name_tag").text(input.files[0].name);
      }
    }

    $("#imag2").change(function() {
      var imgControlName = "#ImgPreview2";
      readURL(this, imgControlName);
      $(".preview2").addClass("it");
      $(".btn-rmv2").addClass("rmv");
    });

    $("#removeImage2").click(function(e) {
      e.preventDefault();
      $("#imag2").val("");
      $("#ImgPreview2").attr("src", "");
      $(".preview2").text("");
      $(".btn-rmv2").removeClass("rmv");
    });
  });
  function removeError() {
    $(".error").html("");
  }
  const { go } = navigation;

  $(document).ready(function() {
    let val = $("#select_option1").val();
    if (val === "NZ Driver Licence") {
      $(".other_sec").hide();
      $(".passport_sec").hide();
    }
    $("#select_option1").change(function() {
      let val = $("#select_option1").val();
      if (val === "NZ Driver Licence") {
        $(".licence_sec").show();
        $(".other_sec").hide();
        $(".passport_sec").hide();
      } else if (val === "NZ Passport") {
        $(".licence_sec").hide();
        $(".other_sec").hide();
        $(".passport_sec").show();
      } else if (val === "Other photo ID") {
        $(".licence_sec").hide();
        $(".other_sec").show();
        $(".passport_sec").hide();
      }
      // reset NZ password select==>
      if (val === "NZ Passport") {
        formData.identificationNumber=""
      }
      if(val === "NZ Passport"){
        formData.expiryDate=""
      }
    });
  });
  const [selected, setSelected] = useState("NZ");
  let countrtyCode = [
    "NZ",
    "AF",
    "AL",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "AZ",
    "BS",
    "BH",
    "BD",
    "BB",
    "BY",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BA",
    "BW",
    "BR",
    "IO",
    "BG",
    "BF",
    "BI",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "CF",
    "TD",
    "CL",
    "CN",
    "CO",
    "KM",
    "CG",
    "CD",
    "CK",
    "CR",
    "CI",
    "HR",
    "CU",
    "CW",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "ER",
    "EE",
    "ET",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "PF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GU",
    "GT",
    "GG",
    "GN",
    "GW",
    "HT",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IR",
    "IQ",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KP",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LB",
    "LS",
    "LR",
    "LY",
    "LI",
    "LT",
    "LU",
    "MO",
    "MK",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "MX",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "MM",
    "NA",
    "NR",
    "NP",
    "NL",
    "NZ",
    "NI",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PS",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RO",
    "RU",
    "RW",
    "KN",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SX",
    "SK",
    "SI",
    "SB",
    "SO",
    "ZA",
    "SS",
    "ES",
    "LK",
    "SD",
    "SR",
    "SZ",
    "SE",
    "CH",
    "SY",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TG",
    "TK",
    "TO",
    "TT",
    "TN",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "UA",
    "AE",
    "GB",
    "US",
    "UY",
    "UZ",
    "VU",
    "VE",
    "VN",
    "VI",
    "YE",
    "ZM",
    "ZW"
  ];
  formData.issueBy = selected;
  const previousHandler = () => {
    go("Third");
  };

  useEffect(() => {
    $(document).ready(function() {
      $(".quantity").keypress(function(e) {
        var regex = new RegExp(/^[a-zA-Z ]+$/);
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
          return true;
        } else {
          e.preventDefault();
          return false;
        }
      });
    });
    $(document).ready(function() {
      $(".numbers").keypress(function(e) {
        if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
          return false;
        }
      });
    });
    $(".digitsOnly").keypress(function(e) {
      var regex = new RegExp(/[0-9a-zA-Z]+/g);
      var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (regex.test(str)) {
        return true;
      } else {
        e.preventDefault();
        return false;
      }
    });
  });
  if (!formData.identificationType) {
    formData.identificationType = "NZ Driver Licence";
  } else {
  }
  const handleNext = () => {
    let val = $("#select_option1").val();
    const expiryDate = document.querySelector("#date").value;
    console.log("expiry date", expiryDate);

    // if(expiryDate < new Date()){
    //   {
    //     $(".licexperror").html("Please select current date");
    //   }
    // }

    formData.identificationType = val;
    formData.expiryDate = expiryDate;
    var x = $("#select_option1 option:selected").val();
    formData.identificationType = x;
    if (x === "NZ Driver Licence" || x === "") {
      if (
        typeof formData.firstName == "undefined" ||
        formData.firstName === "" ||
        formData.firstName === null
      ) {
        $(".licfirsterror").html("Please enter first name");
      } else if (
        typeof formData.lastName == "undefined" ||
        formData.lastName === "" ||
        formData.lastName === null
      ) {
        $(".liclasterror").html("Please enter last name");
      } else if (
        typeof formData.identificationNumber == "undefined" ||
        formData.identificationNumber === "" ||
        formData.identificationNumber === null
      ) {
        $(".licnoerror").html("Please enter licence number");
      }
// Expiry Date Logic
// var date = "1st December,2016";
// var arr1 = date.split(' ');
// var arr2 = arr1[1].split(',');

// console.log('date: ', arr1[0]);
// console.log('month: ', arr2[0]);
// console.log('year: ', arr2[1]);
      var d = new Date();
      var y = d.getFullYear()
      var m = d.getMonth();
      var D = d.getDate();
      var str = expiryDate;
      var year = str.split("/").pop();
      var date=str.split('/');
      var month = date[1].split(',');
      console.log("Date", date[0]);
      console.log("Mh", m+1);

      if  ( !(year >= y))  {
        $(".licexperror").html("Please select valid expiry date");}
        else if (!(month[0] >= m+1)) {
          $(".licexperror").html("Please select valid expiry date");

        }
        else if  (!(date[0] >= D)){

          $(".licexperror").html("Please select valid expiry date");
        }
       
      else if (
        typeof formData.version == "undefined" ||
        formData.version === "" ||
        formData.version === null
      ) {
        $(".licversionerror").html("Please enter version number");
      } else if (formData.version.length < 3) {
        $(".versionerror").html("version number must be 3 digit");
      } else if (
        typeof formData.expiryDate == "undefined" ||
        formData.expiryDate === "" ||
        formData.expiryDate === null
      ) {
        $(".licexperror").html("Please select expiry date");
      } else {
        next();
      }
    } else if (x === "NZ Passport" || x === "") {
      if (
        typeof formData.firstName == "undefined" ||
        formData.firstName === "" ||
        formData.firstName === null
      ) {
        $(".passfirstname").html("Please enter first name");
      } else if (
        typeof formData.lastName == "undefined" ||
        formData.lastName === "" ||
        formData.lastName === null
      ) {
        $(".passlasterror").html("Please enter last name");
      } else if (
        typeof formData.identificationNumber == "undefined" ||
        formData.identificationNumber === "" ||
        formData.identificationNumber === null
      ) {
        $(".passnoerror").html("Please enter passport number");
      } else if (
        typeof formData.expiryDate == "undefined" || 
        formData.expiryDate === "" ||
        formData.expiryDate === null
      ) {
        $(".passexperror").html("Please select expiry date");
      } else {
        next();
      }
    } else if (x === "Other photo ID" || x === "") {
      if (
        typeof formData.expiryDate == "undefined" ||
        formData.expiryDate === "" ||
        formData.expiryDate === null
      ) {
        $(".otherexperror").html("Please select expiry date");
      } else if (
        typeof formData.otherfile == "undefined" ||
        formData.otherfile === "" ||
        formData.otherfile === null
      ) {
        $(".filerror").html("Please attach copy of ID");
      } else {
        next();
      }
    }
  };

  function setDateformateToValid(date) {
    if (
      date !== null &&
      date !== "undefined" &&
      typeof date !== "undefined" &&
      date.includes("/")
    ) {
      let dat = date.split("/");
      dat = dat[2] + "-" + dat[1] + "-" + dat[0];
      return dat;
    }
    // console.log("Date value", date);
  }
  // console.log("formdata");

  useEffect(() => {}, [formData]);

  return (
    <div className="form">
      <div className="headerlogo">
        <img src={logoimage} alt="" />
        <div className="headercontent">
          <h3 className="form_heading">Confirm your ID</h3>
        </div>
      </div>
      <div className="body_content margin-25">
        <div>
          <Container>
            <Row>
              <Col md={6}>
                <label>Select photo ID type</label>
                <select
                  id="select_option1"
                  name="identificationType"
                  onChange={setForm}>
                  <option value="NZ Driver Licence">NZ Driver Licence </option>
                  <option value="NZ Passport">NZ Passport</option>
                </select>
              </Col>
            </Row>
            <div className="licence_sec">
              <Row className="on_other_hide1">
                <Col md={12}>
                  <label>Confirm your full name</label>
                </Col>
                <Col md={6}>
                  <ItemForm
                    tyep="text"
                    name="firstName"
                    maxlength="50"
                    placeholder="First Name"
                    className="quantity"
                    value={formData.firstName ? formData.firstName : ""}
                    onPaste={handleChange}
                    onChange={e => {
                      setForm(e);

                      removeError();
                    }}
                  />
                  <span className="error licfirsterror" />
                </Col>
                <Col md={6}>
                  <ItemForm
                    type="text"
                    name="middleName"
                    maxlength="50"
                    placeholder="Middle Name"
                    onPaste={handleChange}
                    className="quantity"
                    value={formData.middleName ? formData.middleName : ""}
                    onChange={e => {
                      setForm(e);
                      removeError();
                    }}
                  />
                </Col>
                <Col md={6}>
                  <ItemForm
                    type="text"
                    name="lastName"
                    maxlength="50"
                    className="quantity"
                    onPaste={handleChange}
                    placeholder="Last Name"
                    value={formData.lastName ? formData.lastName : ""}
                    onChange={e => {
                      setForm(e);
                      removeError();
                    }}
                  />
                  <span className="error liclasterror" />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label> Licence number</label>
                  <input
                    autoComplete="off"
                    type="text"
                    name="identificationNumber"
                    maxlength="50"
                    placeholder="Enter Licence number"
                    className="digitsOnly"
                    value={
                      formData.identificationNumber
                        ? formData.identificationNumber
                        : ""
                    }
                    onChange={e => {
                      setForm(e);
                      removeError();
                    }}
                  />
                  <span className="error licnoerror" />
                </Col>
                <Col md={6}>
                  <label>Version</label>
                  <InputMask
                    autoComplete="off"
                    mask="999"
                    // maskPlaceholder={null}
                    maskChar={null}
                    className="numbers"
                    name="version"
                    placeholder="Enter version"
                    value={formData.version ? formData.version : ""}
                    onChange={e => {
                      setForm(e);
                      removeError();
                    }}
                  />
                  <span className="error versionerror" />

                  <span className="error licversionerror" />
                </Col>
              </Row>
              <Row>
                {formData.identificationType === "NZ Driver Licence" ? (
                  <Col md={6}>
                    <label>Expiry date</label>
                    <div className="custom_datepiker">
                      <SemanticDatepicker
                        autoComplete="off"
                        id="date"
                        name="expiryDate"
                        minDate={now()}
                        format="DD/MM/YYYY"
                        allowOnlyNumbers
                        value={
                          formData.expiryDate
                            ? new Date(
                                setDateformateToValid(formData.expiryDate)
                              )
                            : ""
                        }
                        onChange={removeError}
                      />
                      <span className="error licexperror" />
                    </div>
                    <span className="error licenseExpiryDateError" />
                  </Col>
                ) : null}
              </Row>
            </div>
            <div className="passport_sec">
              <Row className="on_other_hide1">
                <Col md={12}>
                  <label>Confirm your full name</label>
                </Col>
                <Col md={6}>
                  <input
                    name="firstName"
                    placeholder="First Name"
                    maxlength="50"
                    className="quantity"
                    value={formData.firstName ? formData.firstName : ""}
                    onChange={e => {
                      setForm(e);
                      removeError();
                    }}
                  />
                  <span className="error passfirstname" />
                </Col>
                <Col md={6}>
                  <input
                    name="middleName"
                    placeholder="Middle Name"
                    maxlength="50"
                    className="quantity"
                    value={formData.middleName ? formData.middleName : ""}
                    onChange={e => {
                      setForm(e);
                      removeError();
                    }}
                  />
                </Col>
                <Col md={6}>
                  <input
                    name="lastName"
                    maxlength="50"
                    placeholder="Last Name"
                    className="quantity"
                    value={formData.lastName ? formData.lastName : ""}
                    onChange={e => {
                      setForm(e);
                      removeError();
                    }}
                  />
                  <span className="error passlasterror" />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label>Passport number</label>
                  <input
                    type="text"
                    onKeyDown={e => {
                      if (e.keyCode === 9) e.preventDefault();
                    }}
                    name="identificationNumber"
                    maxlength="50"
                    placeholder="Enter passport number"
                    value={
                      formData.identificationNumber
                        ? formData.identificationNumber
                        : ""
                    }
                    onChange={e => {
                      setForm(e);
                      removeError();
                    }}
                    className="digitsOnly"
                  />
                  <span className="error passnoerror" />
                </Col>
                <Col md={6}>
                  <label>Country of issue</label>
                  <div className="contrybox">
                    <ReactFlagsSelect
                      name="issueBy"
                      countries={countrtyCode}
                      selected={selected}
                      onSelect={code => setSelected(code)}
                      value={FormData.countryCode}
                      onChange={phone => {
                        formData.countryCode = phone;
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                {/*for testinf after remove it  */}
              {/* {formData.identificationType === "NZ Driver License" ? (
                  <Col md={6}>
                    <label>Expiry date</label>
                    <div className="custom_datepiker">
                      <SemanticDatepicker
                        autoComplete="off"
                        id="date"
                        name="expiryDate"
                        minDate={now()}
                        format="DD/MM/YYYY"
                        allowOnlyNumbers
                        value={
                          formData.expiryDate
                            ? new Date(
                                setDateformateToValid(formData.expiryDate)
                              )
                            : ""
                        }
                        onChange={removeError}
                      />
                      <span className="error licexperror" />
                    </div>
                    <span className="error licenseExpiryDateError" />
                  </Col>
                ) : null} */}

                {formData.identificationType === "NZ Passport" ? (
                  <Col md={6}>
                    <label>Expiry date</label>
                    <div className="custom_datepiker">
                      <SemanticDatepicker
                        autoComplete="off"
                        id="date"
                        name="expiryDate"
                        minDate={now()}
                        format="DD/MM/YYYY"
                        allowOnlyNumbers
                        value={
                          formData.expiryDate
                            ? new Date(
                                setDateformateToValid(formData.expiryDate)
                              )
                            : ""
                        }
                        onChange={removeError}
                      />
                      <span className="error passexperror" />
                    </div>
                  </Col>
                ) : null}
              </Row>
            </div>
            <div className="other_sec">
              <Row />
              <Row>
                <Col md={6}>
                  <label>Country of issue</label>
                  <div className="contrybox">
                    <ReactFlagsSelect
                      name="issueBy"
                      countries={countrtyCode}
                      selected={selected}
                      onSelect={code => setSelected(code)}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                {formData.identificationType === "Other photo ID" ? (
                  <Col md={6}>
                    <label>Expiry date</label>

                    <div className="custom_datepiker">
                      <SemanticDatepicker
                        id="date"
                        name="expiryDate"
                        minDate={now()}
                        format="DD/MM/YYYY"
                        allowOnlyNumbers
                        value={
                          formData.expiryDate
                            ? new Date(
                                setDateformateToValid(formData.expiryDate)
                              )
                            : null
                        }
                        onChange={removeError}
                      />
                      <span className="error otherexperror" />
                    </div>
                  </Col>
                ) : null}
              </Row>
              <Row className="fullinput align-items-center">
                <Col xs={1}>
                  <div className="yes">
                    <span className="btn_upload">
                      <input
                        type="file"
                        id="imag2"
                        name="addrbase64"
                        title=""
                        className="UPLODE"
                        accept="image/jpeg,image/png,application/pdf"
                        onClick={removeError}
                        onChange={removeError}
                        required
                      />
                      <img src={attach} alt="icon" className="attach" />
                    </span>
                  </div>
                </Col>

                <Col xs={11}>
                  <label className="ml-6">Attach copy of ID</label>
                  <span className="error file ml-0" />
                </Col>
                <br />
              </Row>
              <div className="spceimg ml-50">
                <span id="name_tag" className="preview2" />
                <input
                  type="button"
                  id="removeImage2"
                  value="x"
                  className="btn-rmv2"
                />
                <span className="error filerror" />
              </div>
            </div>
            <Row>
              <Col md={12}>
                <div className="footer">
                  <button
                    type="button"
                    className="preview trans_btn"
                    onClick={previousHandler}>
                    <img src={backarrow} className="arrow_size" alt="arrow" />{" "}
                    BACK
                  </button>
                  <button
                    type="button"
                    className="next grad_btn"
                    onClick={handleNext}>
                    NEXT
                    <img
                      src={nextarrow}
                      className="arrow_size"
                      alt="arrow"
                    />{" "}
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Four;
