import axios from "axios";
import { Apis } from "../src/components/Configuration";

// const RECT_AAP_URL_Test =
//   "https://1em227ff3l.execute-api.ap-southeast-2.amazonaws.com/dev";
// const RECT_AAP_URL_PROD = "";
// const RECT_AAP_URL_STAG =

//   "https://y5aqjc3v76.execute-api.ap-southeast-2.amazonaws.com/api";
const instance = axios.create({
  baseURL: Apis
});

export default instance;
