import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../images/logo.png";
import nextarrow from "../images/nextarrow.png";
const Welcome = ({ navigation }) => {
  const { next ,go} = navigation;
//  go("Third");

  return (
    <div className="form">
      <div className="headerlogo">
        <img src={logo} alt="" />
        <div className="headercontent">
          <h3 className="form_heading">Let’s get started!</h3>
        </div>
      </div>

      <div className="body_content margin-25">
        <Container>
          <Row>
            <Col md={12}>
              <label>
                This will only take a minute or two, make sure you've got the
                following information on hand:
              </label>
            </Col>
          </Row>
          <Row className="">
            <Col md={12}>
              <div className="dot-icon">
                <p>Photo ID (Driver Licence or Passport)</p>
                <p>IRD number</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="footer">
                <button type="button" className="next grad_btn" onClick={next}>
                  NEXT
                  <img
                    src={nextarrow}
                    className="arrow_size"
                    alt="arrow"
                  />{" "}
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Welcome;
