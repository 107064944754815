import React from "react";
import MultiStepForm from "./MultiStepForm";
import AppContext from "./AppContext";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "./styles.css";
import "./aurora.css";

const App = () => (
  <AppContext.Provider value={{ isReviewMode: false }}>
    <div>
      <form className="w3-container w3-margin-top w3-margin-bottom">
        <div className="account-btn">
          {/* <button type="button" className="accont-btn">
            <a href="#" className="actext">Already have an account? Login</a>
          </button> */}
        </div>

        <MultiStepForm />
      </form>
    </div>
  </AppContext.Provider>
);

export default App;
