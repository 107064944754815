import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import nextarrow from "../images/nextarrow.png";
import backarrow from "../images/backarrow.png";
import $ from "jquery";
import logo from "../images/logo.png";
import "react-phone-input-2/lib/style.css";
import AxiosInstnce from "../axios";
// import { check_evn } from "./Configuration";

const First = ({ setForm, formData, navigation }) => {
  const { previous } = navigation;
  const { next } = navigation;
  useEffect(() => {
    $(document).ready(function() {
      $(".quantity").keypress(function(e) {
        if (
          (e.which >= 65 && e.which <= 90) ||
          e.which === 32 ||
          e.which === 45 ||
          e.which === 38 ||
          (e.which >= 97 && e.which <= 122)
        ) {
          return true;
        } else {
          return false;
        }
      });
    });

    $(document).ready(function() {
      $(".numbers").keypress(function(e) {
        if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
          return false;
        }
      });
    });
    $(".digitsOnly").keypress(function(e) {
      var regex = new RegExp(/[0-9A-Za-z-!# :]+/g);
      var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (regex.test(str)) {
        return true;
      } else {
        e.preventDefault();
        return false;
      }
    });
  });

  function handleClick() {
    let emailExpression = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let passwordExpression = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    if (
      typeof formData.email === "undefined" ||
      formData.email === "" ||
      formData.email === null
    ) {
      setErrorResults(emailerror);
    } else if (
      searchResults === false ||
      searchResults === "undefined" ||
      searchResults === "" ||
      searchResults === null
    ) {
      $(".emailerror").html(
        "Please enter a valid email address, such as example@email.com"
      );
    } else if (!emailExpression.test(formData.email)) {
      $(".emailerror").html(
        "Please enter a valid email address, such as example@email.com"
      );
    }
    // else if (
    //   typeof formData.password == "undefined" ||
    //   formData.password === "" ||
    //   formData.password === null ||
    //   !passwordExpression.test(formData.password)
    // ) {
    //   $(".passworderror")
    //     .html(
    //       "Password must be 8 – 16 characters, and contain at least one number, one uppercase and lowercase letter, and one special character"
    //     )
    //     .css("color", "red");
    // }
    else {
      next();
    }
  }
  function setPass() {
    let passwordExpression = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    if (!passwordExpression.test(formData.password)) {
      $(".passworderror")
        .html(
          "Password must be 8 – 16 characters, and contain at least one number, one uppercase and lowercase letter, and one special character"
        )
        .css("color", "gray");
    } else {
      removeError();
    }
  }
  function removeError() {
    $(".passworderror").html("");
  }
  $("input").on("paste", function() {
    $(".passworderror").html("");
  });

  // useEffect(() => {
  //   $(document).ready(function () {

  //     $(".letterOnly").keypress(function (e) {
  //       var regex = new RegExp(/^[a-z0-9 ]+$/);
  //       var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  //       if (regex.test(str)) {
  //         return true;
  //       } else {
  //         e.preventDefault();
  //         return false;
  //       }

  //     });

  //   });
  // })

  useEffect(() => {}, [formData]);

  const useDebouncedCallback = (func, wait) => {
    const timeout = useRef();

    return useCallback(
      (...args) => {
        const later = () => {
          clearTimeout(timeout.current);
          func(...args);
        };

        clearTimeout(timeout.current);
        timeout.current = setTimeout(later, wait);
      },
      [func, wait]
    );
  };

  const [searchResults, setSearchResults] = useState("");
  const [errorResults, setErrorResults] = useState();
  const [errorLoader, setErrorLoader] = useState();
  let emailExpression = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let emailerror =
    "Please enter a valid email address, such as example@email.com ";
  let alreadyemail = "An account already exists under this email address";

  const emailSubmitt = async () => {
    setErrorLoader(true);
    await AxiosInstnce.get("/users/" + formData.email).then(res => {
      setErrorLoader(false);
      res.status === 200
        ? setSearchResults(true)
        : setErrorResults(alreadyemail);
    });
  };

  const onSearch = useDebouncedCallback(() => {
    if (emailExpression.test(formData.email)) {
      emailSubmitt();
    } else {
      if (formData.email) {
        setErrorResults(emailerror);
      } else {
      }
    }
  }, 700);

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(
    () => {
      onSearch();
    },
    [formData.email]
  );
  const handleChange = e => {
    e.preventDefault();
  };
  return (
    <div className="form">
      <div className="headerlogo">
        <img src={logo} alt="" />
        <div className="headercontent">
          <h3 className="form_heading">Create an account with your email</h3>
        </div>
      </div>
      <div className="body_content margin-25">
        <div>
          <Container>
            <Row>
              <Col md={6} className="col-10">
                <label>Email address</label>
                <input
                  autoComplete="off"
                  value={formData.email ? formData.email : ""}
                  name="email"
                  placeholder="Email address"
                  autoComplete="on"
                  className="letterOnly"
                  onChange={e => {
                    setForm(e);
                    setErrorResults(!errorResults);
                    setErrorLoader("");
                    setSearchResults("");
                  }}
                />

                <span className="error emailerror">{errorResults}</span>
              </Col>
              <Col md={6} className="mainloader_div col-2">
                <div className="loaderdiv">
                  {errorLoader === true ? (
                    <div className="loaderemail" />
                  ) : searchResults === true ? (
                    <i className="far fa-check-circle color" />
                  ) : null}
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col md={6}>
                <label>Create password</label>
                <input
                  // type="password"
                  name="password"
                  placeholder="Create password"
                  className=""
                  autoComplete="new-password"
                  type={passwordShown ? "text" : "password"}

                  value={formData.password ? formData.password : ""}
                  onChange={e => {
                    setForm(e);
                    setPass(e);

                  }}
                />
                <i onClick={togglePasswordVisiblity} className="eye-password">
                  {" "}
                  <i className="fa fa-eye" aria-hidden="true" />
                </i>

                <span className="error passworderror" />
              </Col>
            </Row> */}
            <Row>
              <Col md={6}>
                <label>Adviser/Influencer code (if relevant)</label>
                <input
                  autoComplete="off"
                  onPaste={handleChange}
                  name="adviserCode"
                  maxlength="20"
                  placeholder="Optional: Enter code"
                  className="digitsOnly"
                  value={formData.adviserCode ? formData.adviserCode : ""}
                  onChange={e => {
                    setForm(e);
                  }}
                />

                <span className="error" />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="footer">
                  <button
                    type="button"
                    className="preview trans_btn"
                    onClick={previous}>
                    <img src={backarrow} className="arrow_size" alt="arrow" />{" "}
                    BACK
                  </button>
                  <button
                    type="button"
                    className="next grad_btn"
                    onClick={handleClick}>
                    NEXT
                    <img
                      src={nextarrow}
                      className="arrow_size"
                      alt="arrow"
                    />{" "}
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default First;
